// extracted by mini-css-extract-plugin
export var lbContainerOuter = "B_tr";
export var lbContainerInner = "B_ts";
export var movingForwards = "B_tt";
export var movingForwardsOther = "B_tv";
export var movingBackwards = "B_tw";
export var movingBackwardsOther = "B_tx";
export var lbImage = "B_ty";
export var lbOtherImage = "B_tz";
export var prevButton = "B_tB";
export var nextButton = "B_tC";
export var closing = "B_tD";
export var appear = "B_tF";