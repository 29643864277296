// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_qP g_hp g_dw";
export var heroHeaderCenter = "r_hq g_hq g_dw g_fz";
export var heroHeaderRight = "r_hr g_hr g_dw g_fB";
export var heroParagraphLeft = "r_qQ g_hl g_dz";
export var heroParagraphCenter = "r_hm g_hm g_dz g_fz";
export var heroParagraphRight = "r_hn g_hn g_dz g_fB";
export var heroBtnWrapperLeft = "r_qR g_bg g_f0 g_bD g_cD g_cK";
export var heroBtnWrapperCenter = "r_qS g_f1 g_f0 g_bD g_cD g_cH";
export var heroBtnWrapperRight = "r_qT g_f2 g_f0 g_bD g_cD g_cL";
export var overlayBtnWrapper = "r_qV g_hk g_b6 g_cf g_cg g_ch g_cr g_dg";
export var design4 = "r_qW g_hj g_b6 g_cf g_cg g_cr";
export var heroExceptionSmall = "r_qX v_qX";
export var heroExceptionNormal = "r_qY v_qY";
export var heroExceptionLarge = "r_qZ v_qZ";
export var Title1Small = "r_q0 v_q0 v_rR v_rS";
export var Title1Normal = "r_q1 v_q1 v_rR v_rT";
export var Title1Large = "r_q2 v_q2 v_rR v_rV";
export var BodySmall = "r_q3 v_q3 v_rR v_r8";
export var BodyNormal = "r_q4 v_q4 v_rR v_r9";
export var BodyLarge = "r_q5 v_q5 v_rR v_sb";