// extracted by mini-css-extract-plugin
export var alignDiscLeft = "s_q6 g_gm g_cK g_fy";
export var alignLeft = "s_qr g_gm g_cK g_fy";
export var alignDiscCenter = "s_q7 g_gn g_cH g_fz";
export var alignCenter = "s_cS g_gn g_cH g_fz";
export var alignDiscRight = "s_q8 g_gp g_cL g_fB";
export var alignRight = "s_qs g_gp g_cL g_fB";
export var footerContainer = "s_q9 g_fW g_cZ";
export var footerContainerFull = "s_rb g_fT g_cZ";
export var footerHeader = "s_kZ g_kZ";
export var footerTextWrapper = "s_rc g_bD";
export var footerDisclaimerWrapper = "s_k4 g_k4 g_dn";
export var badgeWrapper = "s_rd g_bD g_cD g_cS g_cJ g_cM";
export var footerDisclaimerRight = "s_rf undefined";
export var footerDisclaimerLeft = "s_rg undefined";
export var verticalTop = "s_rh g_cD g_cM g_cR g_cP";
export var firstWide = "s_rj";
export var disclaimer = "s_rk g_cD g_cM";
export var socialDisclaimer = "s_rl";
export var left = "s_rm";
export var wide = "s_rn g_dC";
export var right = "s_rp g_cN";
export var line = "s_gh g_gj g_dy";
export var badgeDisclaimer = "s_rq g_cG g_cS g_cM";
export var badgeContainer = "s_rr g_cD g_cL g_cS";
export var badge = "s_rs";
export var padding = "s_rt g_fb";
export var end = "s_rv g_cL";
export var linkWrapper = "s_rw g_fF";
export var link = "s_bm g_fF";
export var colWrapper = "s_rx g_dB";
export var consent = "s_f g_f g_cG g_cS";
export var media = "s_ry g_cB g_fC";
export var itemRight = "s_rz";
export var itemLeft = "s_rB";
export var itemCenter = "s_rC";
export var exceptionWeight = "s_rD v_sh";