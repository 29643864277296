// extracted by mini-css-extract-plugin
export var campaignContainer = "n_p5 g_fW";
export var campaignContainerFull = "n_p6 g_fT";
export var wrapper = "n_p7 g_bD";
export var leftWrapper = "n_p8 g_cD g_cR";
export var rightWrapper = "n_p9";
export var subtitle = "n_qb";
export var title = "n_qc";
export var text = "n_p1 g_dw";
export var inlineWrapper = "n_qd g_cG g_cK g_bD";
export var btnsWrapper = "n_qf n_qd g_cG g_cK g_bD g_cM";
export var priceWrapper = "n_qg n_qd g_cG g_cK g_bD g_dl g_cS";
export var priceHeading = "n_qh";
export var disclaimerWrapper = "n_qj g_dH";
export var mediaSlot = "n_qk";
export var listWrapper = "n_ql g_cG g_cS g_bD";
export var btnWrapper = "n_bg";
export var imageWrapper = "n_qm g_gd g_b5";
export var SmallSmall = "n_qn v_qn v_rR v_sc";
export var SmallNormal = "n_qp v_qp v_rR v_sd";
export var SmallLarge = "n_qq v_qq v_rR v_r9";