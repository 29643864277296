// extracted by mini-css-extract-plugin
export var articleWrapper = "m_pn";
export var articleText = "m_pp g_fy g_dw";
export var header = "m_pq g_b5 g_bD";
export var headerImageWrapper = "m_pr g_cl g_b6 g_cg g_cf g_cb g_ch g_cn g_cV";
export var headerGradient = "m_ps g_cl g_b6 g_cg g_cf g_cb g_ch";
export var headerGradientOverlay = "m_pt g_cl g_b6 g_cg g_cf g_cb g_ch";
export var headerContentWrapper = "m_pv g_cD g_cS g_cH g_cM g_cp g_b5";
export var headerContentWrapperAlt = "m_pw g_cD g_cS g_cH g_cM g_cp g_b5";
export var contentWrapper = "m_px g_bD";
export var dateTag = "m_py g_dj g_dv g_ft g_bD";
export var icon1 = "m_pz g_dS";
export var icon2 = "m_pB g_dS g_dM";
export var tagBtn = "m_pC g_c4 g_dH g_fs g_fF";
export var headerText = "m_pD g_bD g_dj g_dw g_cZ";
export var center = "m_pF g_fz";
export var videoIframeStyle = "m_pG g_f3 g_bD g_bP g_cC g_c4 g_bY";
export var articleImageWrapper = "m_pH g_dw g_b5";
export var articleImageWrapperIcon = "m_pJ g_dw g_b5";
export var articleRow = "m_pK g_cH";
export var quoteWrapper = "m_hH g_cG g_cS g_dw";
export var quoteBar = "m_pL g_bP";
export var quoteText = "m_pM";
export var authorBox = "m_pN g_bD";
export var authorRow = "m_pP g_cH g_cZ g_dC";
export var separator = "m_pQ g_bD";
export var line = "m_gh g_bD g_gh g_d1 g_dC";
export var authorImage = "m_l4 g_db g_b5 g_bD g_dx";
export var authorText = "m_pR g_dl g_dx";
export var masonryImageWrapper = "m_pS";
export var bottomSeparator = "m_pT g_bD g_dC";
export var linksWrapper = "m_pV g_dl g_fy";
export var comments = "m_pW g_cH g_dC";
export var sharing = "m_pX g_cD g_cH g_cM";
export var shareText = "m_pY g_bD g_fz";
export var icon = "m_pZ";
export var customRow = "m_p0 g_cH g_b5";
export var text = "m_p1";
export var SubtitleSmall = "m_p2";
export var SubtitleNormal = "m_p3";
export var SubtitleLarge = "m_p4";