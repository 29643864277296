// extracted by mini-css-extract-plugin
export var customText = "q_qJ g_fy g_dw g_dk";
export var videoIframeStyle = "q_pG g_f3 g_bD g_bP g_cC g_c4 g_bY";
export var customImageWrapper = "q_qK g_dw g_dk g_b5";
export var customRow = "q_p0 g_cH g_l";
export var quoteWrapper = "q_hH g_cG g_cS g_dw g_dk";
export var quoteBar = "q_pL g_bP";
export var masonryImageWrapper = "q_pS";
export var title = "q_qc";
export var Title3Small = "q_qL v_qL v_rR v_rZ";
export var Title3Normal = "q_qM v_qM v_rR v_r0";
export var Title3Large = "q_qN v_qN v_rR v_r1";