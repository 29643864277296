import React from 'react';
import HTMLParser from 'html-react-parser';
import Masonry from 'react-masonry-css';

import Lightbox from '../../../../Lightbox';
import SectionMedia from '../../LayoutComponents/SectionMedia';
import { getEmbedUrl } from '../../../../../helper';
import CustomSectionMedia from '../../LayoutComponents/CustomSectionMedia';

import * as styles from './styles.module.css';

class CustomLayout extends React.PureComponent {
  constructor(props) {
    const images = [];
    if (props.section) {
      props.section.data.forEach((element) => {
        if (element.type === 'GALLERY' && element.lightbox === true) {
          element.data.forEach((img) => {
            images.push(img);
          });
        }
      });
    }

    super(props);

    this.state = {
      section: props.section,
      color0: props.themeData.colors[0],
      images,
      photoIndex: 0,
      isOpen: false,
    };
  }

  createText = (item, index, quote) => {
    const cols = quote === true ? '' : 'col-10 col-lg-8';
    const id = `${this.state.section._id}_P_${index}_section`;
    let content;
    let styleNames;

    if (item.type && item.type === 'HEADINGS/HEADING-ONE' && item.active) {
      content = <h1 className={styles.title}>{HTMLParser(item.text)}</h1>;
      styleNames = styles.customText;
    } else if (item.type && item.type === 'HEADINGS/HEADING-TWO') {
      content = <h2>{HTMLParser(item.text)}</h2>;
      styleNames = styles.customText;
    } else if (item.type && item.type.startsWith('PARAGRAPH')) {
      content = <span>{HTMLParser(item.text)}</span>;
      styleNames = quote === true ? undefined : styles.customText;
    }

    const elem = (
      <div key={id} className={`${cols} ${styleNames}`}>
        {content}
      </div>
    );

    return elem;
  };

  createImage = (item, index) => {
    const widthMultiplier = 0.84;

    return (
      <div key={`${this.props.section._id}_Image_${index}_section`} className="col-10">
        <div className={styles.customImageWrapper}>
          <CustomSectionMedia
            sizes={`(min-width: 1200px) ${1140 * widthMultiplier}px, (min-width: 992px) ${
              960 * widthMultiplier
            }px, (min-width: 768px) ${720 * widthMultiplier}px, (min-width: 576px) ${540 * widthMultiplier}px, 84vw`}
            data={item}
            images={this.props.images}
            pagePathList={this.props.pagePathList}
            articlePathList={this.props.articlePathList}
            filePathList={this.props.filePathList}
            categoryPathList={this.props.categoryPathList}
            sectionSlugs={this.props.sectionSlugs}
            colors={this.props.themeData.colors}
            loadingStrategy={this.props.isFirst ? 'eager' : undefined}
          />
        </div>
      </div>
    );
  };

  createVideo = (item, index) => {
    let videoUrl;
    let video;
    if (item.videoID) videoUrl = getEmbedUrl(item);

    if (videoUrl) {
      video = (
        <div className="col-10 col-lg-8" key={`${this.state.section._id}_Custom_Preview_${index}_Video`}>
          <iframe
            frameBorder="0"
            className={styles.videoIframeStyle}
            src={videoUrl}
            allowFullScreen
            aria-hidden="true"
          />
        </div>
      );
    }

    return video;
  };

  createGallery = (items, index, lightbox) => {
    const pictures = [];
    const columnsCount = Number(this.state.section.data[index].columns) || 1;

    // inside col-10
    const sizes = [
      '(max-width: 576px) 84vw',
      `(min-width: 576px and max-width: 768px) ${columnsCount === 1 ? 84 : 42}vw`,
      `(min-width: 768px) ${84 / columnsCount}vw`,
    ].join(', ');

    const { crop } = this.state.section.data[index];

    items.forEach((item, i) =>
      pictures.push(
        <div key={`${this.props.section._id}_Image_${i}${index}_section`}>
          <div className={styles.customImageWrapper} style={lightbox ? { cursor: 'pointer' } : undefined}>
            <SectionMedia
              mediaType={item.icon ? 'ICON' : 'IMAGE'}
              wrapperStyleName={crop ? 'imageWrapper100' : 'imageContent5'}
              imageStyleName={crop ? 'galleryImg' : 'imageFull'}
              iconStyleName=""
              sizes={sizes}
              onImageClick={lightbox ? this.handleImageClick : undefined}
              src={item.CDNLink ? item.CDNLink : `${process.env.IMAGES_CDN}/${item.src}`}
              alt={item.alt}
              data={item}
              images={this.props.images}
              pagePathList={this.props.pagePathList}
              articlePathList={this.props.articlePathList}
              filePathList={this.props.filePathList}
              categoryPathList={this.props.categoryPathList}
              sectionSlugs={this.props.sectionSlugs}
              colors={this.props.themeData.colors}
              loadingStrategy={this.props.isFirst ? 'eager' : undefined}
            />
          </div>
        </div>,
      ),
    );

    let gallery;
    const md = this.state.section.data[index].columns && Number(this.state.section.data[index].columns) === 1 ? 1 : 2;

    if (this.state.section.data[index].crop !== true) {
      const breakpointColumnsObj = {
        default: Number(this.state.section.data[index].columns),
        768: md,
        576: 1,
      };

      gallery = (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          style={{ display: 'flex', width: '100%' }}
          className=""
          columnClassName=""
        >
          {pictures.map((pic, i) => (
            <div key={`${this.state.section._id}_Gallery_Image${index}${i}`} className={styles.masonryImageWrapper}>
              {pic}
            </div>
          ))}
        </Masonry>
      );
    } else {
      gallery = [];
      const cols = `col-12 col-sm-${12 / md} col-md-${12 / Number(this.state.section.data[index].columns)}`;
      pictures.forEach((p, i) => {
        const pic = (
          <div className={cols} key={`${this.state.section._id}_Gallery_Image${index}${i}`}>
            {p}
          </div>
        );

        return gallery.push(pic);
      });
    }

    const result = (
      <div key={`${this.state.section._id}_Gallery_${index}`} className="col-10">
        <div className="row">{gallery}</div>
      </div>
    );

    return result;
  };

  createQuote = (item, index) => {
    const quote = (
      <div className={`col-8 ${styles.quoteWrapper}`} key={`${this.state.section._id}_Custom_${index}_Quote`}>
        <div className={styles.quoteBar} style={{ backgroundColor: this.state.color0 }} />
        <div>
          <div style={{ height: '10px' }} />
          {this.createText(item, index, true)}
          <div style={{ height: '10px' }} />
        </div>
      </div>
    );

    return quote;
  };

  handleImageClick = (event) => {
    const photoIndex = this.state.images.findIndex((image) => image.id === event.target.dataset.imageid);

    if (photoIndex > -1) {
      this.setState({
        photoIndex,
        isOpen: true,
      });
    }
  };

  handleCloseClick = () => {
    this.setState({
      isOpen: false,
    });
  };

  handleMoveNextRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
    });
  };

  handleMovePrevRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + (this.state.images.length - 1)) % this.state.images.length,
    });
  };

  render() {
    const content = [];
    if (this.state.section.data) {
      this.state.section.data.forEach((item, index) => {
        let elem;
        if (
          item.type === 'HEADINGS/HEADING-ONE' ||
          item.type === 'HEADINGS/HEADING-TWO' ||
          item.type === 'PARAGRAPH/PARAGRAPH'
        ) {
          elem = this.createText(item, index);
        } else if (item.type === 'PARAGRAPH/QUOTE') {
          elem = this.createQuote(item, index);
        } else if (item.type === 'IMAGE') {
          elem = this.createImage(item.content, index);
        } else if (item.type === 'VIDEO') {
          elem = this.createVideo(item, index);
        } else if (item.type === 'GALLERY') {
          elem = this.createGallery(item.data, index, item.lightbox);
        }

        content.push(elem);
      });
    }

    return (
      <div className="container longarticle">
        {this.state.images.length > 0 && (
          <Lightbox
            mainImageId={this.state.images[this.state.photoIndex].id}
            nextImageId={this.state.images[(this.state.photoIndex + 1) % this.state.images.length].id}
            prevImageId={
              this.state.images[(this.state.photoIndex + (this.state.images.length - 1)) % this.state.images.length].id
            }
            onCloseRequest={this.handleCloseClick}
            onMovePrevRequest={this.handleMovePrevRequest}
            onMoveNextRequest={this.handleMoveNextRequest}
            visible={this.state.isOpen}
            images={this.props.images}
          />
        )}
        <div className={`row ${styles.customRow}`}>{content}</div>
      </div>
    );
  }
}

export default CustomLayout;
