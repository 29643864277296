// extracted by mini-css-extract-plugin
export var alignLeft = "p_qr g_gm g_cK g_fy";
export var alignCenter = "p_cS g_gn g_cH g_fz";
export var alignRight = "p_qs g_gp g_cL g_fB";
export var contactFormWrapper = "p_h7 g_h7 g_cX g_c8";
export var contactFormText = "p_qt";
export var inputFlexColumn = "p_qv";
export var inputFlexRow = "p_qw";
export var contactForm = "p_h5 g_h5 g_bD g_cM";
export var contactFormHeader = "p_h8 g_h8 g_d2 g_d6";
export var contactFormParagraph = "p_h9 g_h9 g_dY g_d6";
export var contactFormSubtitle = "p_jb g_jb g_dZ g_d6";
export var contactFormLabel = "p_h6 g_h6 g_bD g_cC";
export var contactFormInputSmall = "p_jg g_jg g_bD g_c6 g_c8";
export var contactFormInputNormal = "p_jh g_jh g_bD g_c6 g_c8";
export var contactFormInputLarge = "p_jj g_jj g_bD g_c6 g_c8";
export var contactFormTextareaSmall = "p_jc g_jc g_bD g_c6 g_c8";
export var contactFormTextareaNormal = "p_jd g_jd g_bD g_c6 g_c8";
export var contactFormTextareaLarge = "p_jf g_jf g_bD g_c6 g_c8";
export var contactRequiredFields = "p_jk g_jk g_bD g_cD";
export var inputField = "p_qx";
export var inputOption = "p_qy";
export var inputOptionRow = "p_qz";
export var inputOptionColumn = "p_qB";
export var radioInput = "p_qC";
export var select = "p_qD";
export var contactBtnWrapper = "p_qF g_f1 g_f0 g_bD g_cD g_cH";
export var sending = "p_qG";
export var blink = "p_qH";