// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "w_sS g_cH";
export var storyRowWrapper = "w_jn g_jn g_cN";
export var storyLeftWrapper = "w_sT g_cD g_cS";
export var storyWrapperFull = "w_sV g_dH";
export var storyWrapperFullLeft = "w_nL g_dH g_cD g_cS g_cH";
export var contentWrapper = "w_px g_jp";
export var storyLeftWrapperCenter = "w_sW g_jq g_cD g_cH g_cS g_cM";
export var storyRightWrapperCenter = "w_sX g_jv";
export var storyHeader = "w_sY g_jt g_bD g_dw";
export var storyHeaderCenter = "w_js g_js g_bD g_dw g_fz g_cC";
export var storyParagraphCenter = "w_jr g_jr g_cC g_fz";
export var storyBtnWrapper = "w_sZ g_bg g_f0 g_bD g_cD g_cK";
export var storyBtnWrapperCenter = "w_jx g_jx g_f0 g_bD g_cD g_cH";
export var imageWrapper = "w_qm g_gd g_b5";
export var imageWrapperFull = "w_s0 g_bD g_bP g_l g_b5";