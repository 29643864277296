// extracted by mini-css-extract-plugin
export var warningBackgroundlight = "f_8";
export var warningBackgrounddark = "f_9";
export var warningBackgroundcustom = "f_bb";
export var bottom = "f_bc";
export var modal = "f_bd";
export var container = "f_bf";
export var btnWrapper = "f_bg g_cH g_cS g_cM";
export var elementWrapper = "f_bh g_cH g_cS g_fz";
export var titleWrapper = "f_bj g_cH g_cS";
export var cookieButtons = "f_bk g_cD g_cH g_cS g_cM";
export var btn = "f_bl g_cD g_cH g_cS";
export var link = "f_bm";
export var decline = "f_bn f_bl g_cD g_cH g_cS";
export var row = "f_bp";